<style scoped lang="scss">
@import './DropdownAccountStyle.scss';
</style>

<template>
<div class="flex items-center">
  <client-only>
    <div :key="forceRerender" v-show="is_verified || userInfo.user_id" class="mr-30">
      <tf-chat-notify />
    </div>
  </client-only>
  <div
    v-if="is_verified || userInfo.user_id"
    class="right-account flex items-center"
    v-ClickOutside="closeAccountDropdown">
    <button
      type="button"
      class="right-account--button-login"
      title="View account"
      aria-label="View account"
      @click="openAccountDropdown"
    >
      <div id="circle_avatar" class="circle" >
        <template v-if='!avatar'><span id="span_name">{{ getUserName }}</span></template>
        <template v-if='avatar'><img id="user_avatar" class='img-cover' :src="avatar" alt="User login icon" width='24' height='24' /></template>
      </div>
      <img src="https://s3-cdn.designerjourneys.com/static/vectors/general/chevron-down.svg" width='8' height='5' alt='chevron down' />
    </button>
    <div
      :class="{
        active: showAccountDropdown,
        'right-account--dropdown': true,
      }"
    >
      <ul>
        <li>
          <router-link :to="{name: goToEnquiries()}" title="Go to page: Enquiries" aria-label="Go to page: Enquiries">
            <span>Enquiries</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{name: goToBookings()}" title="Go to page: Bookings" aria-label="Go to page: Bookings">
            <span>Bookings</span>
          </router-link>
        </li>
        <li v-if="domain==='agent'">
          <router-link :to="{name: goToFAQ()}" title="Go to page: FAQ" aria-label="Go to page: FAQ">
            <span>FAQ</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{name: goToAccountSettings()}" title="Go to page: Account Settings" aria-label="Go to page: Account Settings">
            <span>Account Settings</span>
          </router-link>
        </li>
        <li @click="handleLogout">
          <div style="color: #000;font-size: 12px;line-height: 14px;font-weight: normal; display: inline-block"
            class="hand"
            title="Go to page: Logout"
            aria-label="Go to page: Logout"
          >
            <span>Logout</span>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div
    class="right-account"
    v-ClickOutside="closeAccountDropdown"
    v-else
  >
    <button
      type="button"
      class="right-account--button"
      title="View account"
      aria-label="View account"
      @click="openAccountDropdown"
    >
      <img src="https://s3-cdn.designerjourneys.com/static/icons/user.svg" class='mr-10' alt="User login icon" width='24' height='24'/>

      <img src="https://s3-cdn.designerjourneys.com/static/vectors/general/chevron-down.svg" width='8' height='5' alt='chevron down' />
    </button>
    <div
      :class="{
        active: showAccountDropdown,
        'right-account--dropdown': true,
      }"
    >
      <ul>
        <li>
          <div style="color: #000;font-size: 12px;line-height: 14px;font-weight: normal; display: inline-block" @click="toLogin" title="Go to page: Login" aria-label="Go to page: Login" class="hand">
            <span>Login</span>
          </div>
        </li>
        <li>
          <div
            style="color: #000;font-size: 12px;line-height: 14px;font-weight: normal; display: inline-block"
            @click="toSignUp"
            title="Go to page: Signup"
            aria-label="Go to page: Signup"
            class="hand"
          >
            <span>Sign Up</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <PopupCommon
    :modalTitle="'Enquiry Details'"
    :modalActive="showModalEnquiryDetail"
    @update:close="closeModalEnquiryDetail()"
    maxWidth='600'
    overflowBody
  >
    <template #content>
      <AppTable :datas="enquiryDetailList" type="vertical"></AppTable>
    </template>
  </PopupCommon>
</div>
</template>

<script>
import ClickOutside from '~/directives/ClickOutside'
import { ref, computed, onMounted, /*watchEffect,*/ watchPostEffect, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
// import { getCookie, setCookie } from '~/mixins/cookie'
import { useGetCookie } from '~/composables/common/useCustomCookie'
import { publicDomain } from '~/helper'
import { TfChat, TfChatNotify } from 'tf-chat'
import { pages } from '~/constant/redirectLogout'
import AppTable from '~/components/ui/table/AppTable'
import PopupCommon from '~/components/ui/popup/PopupCommon'
import { useAuthStore } from '~/store/pinia/auth'
import { useGeneralInfoStore } from '~/store/pinia/generalInfo'

export default {
  props: {
    theme: String
  },
  directives: {
    ClickOutside,
  },
  components:{
    TfChatNotify,
    AppTable,
    PopupCommon
  },
  setup(){
    const loaded = ref(false)
    const authStore = useAuthStore()
    const router = useRouter()
    const route = useRoute()
    const generalStore = useGeneralInfoStore()

    const showAccountDropdown = ref(false)
    const openAccountDropdown = () => {
      showAccountDropdown.value = !showAccountDropdown.value
    }
    const closeAccountDropdown = () => {
      showAccountDropdown.value = false
    }
    const domain = publicDomain()

    const toSignUp = () =>{
      // if (domain === 'agent') {
      //   router.push({ name: 'signup-agent' })
      // } else{
        router.push({ name: 'signup-traveller' })
      // }
    }

    const toLogin = () =>{
      // if (domain === 'agent') {
      //   router.push({ name: 'home' })
      // } else{
        router.push({ name: 'login-traveller' })
      // }
    }
    const forceRerender = ref(0)
    //const is_admin = useGetCookie("is_admin")
    const is_verified = useGetCookie("is_verified")
    const dj_xxer_hash = useGetCookie("dj_xxer_hash", 365)
    const dj_uid = useGetCookie("dj_uid", 365)
    const firstName = ref('')
    const lastName = ref('')
    const avatar = ref(null)
    const getUserName = ref('')
    const tokenChat = ref('')
    const userInfo = computed(() => authStore.getUserInfo)

    const userLogin = async () => {
      if(is_verified.value || userInfo.value.user_id){
        // const checkHash = getCookie("dj_xxer_hash=")
        try{
          await authStore.verify()
          if(userInfo.value.status === 200) {
            firstName.value = userInfo.value.firstName
            lastName.value = userInfo.value.lastName
            avatar.value = userInfo.value.avatar
            getUserName.value = firstName.value.charAt(0).toUpperCase() + lastName.value.charAt(0).toUpperCase() || ''
            loaded.value = true
            tokenChat.value = userInfo.value.token
            let sdkToken = tokenChat.value
            sdkToken && await TfChat.init(sdkToken)
            if(!dj_xxer_hash.value){
              // setCookie('dj_xxer_hash',  userInfo.value.hash, COOKIE_DURATION)
              dj_xxer_hash.value = userInfo.value.hash
            }
          } else {
            await authStore.logout()
            is_verified.value = null
            dj_xxer_hash.value = null
            dj_uid.value = null
            //is_admin.value = null
            window.location.reload()
            localStorage.removeItem('chat_array')
            sessionStorage.removeItem('auth_path')
          }
        } catch(err){
          console.log('userLogin error: ',err)
          if((err?.status || err?.response?.status) === 401){
            // const COOKIE_DURATION = 0
            await authStore.logout()
            // setCookie('is_verified', 0, COOKIE_DURATION)
            // setCookie('dj_xxer_hash', 0, COOKIE_DURATION)
            is_verified.value = null
            dj_xxer_hash.value = null
            dj_uid.value = null
            //is_admin.value = null
            window.location.reload()
            localStorage.removeItem('chat_array')
            sessionStorage.removeItem('auth_path')
          }
        }
      }
    }

    onMounted(async ()=>{
      await nextTick(async()=> await userLogin())
    })

    const handleLogout = async () =>{
      // const COOKIE_DURATION = 0
      await authStore.logout()
      // setCookie('is_verified', 0, COOKIE_DURATION);
      // setCookie('dj_xxer_hash', 0, COOKIE_DURATION);
      is_verified.value = null
      dj_xxer_hash.value = null
      dj_uid.value = null
      //is_admin.value = null
      localStorage.removeItem('chat_array');
      sessionStorage.removeItem('auth_path');
      sessionStorage.removeItem('dj_uid');
      if (pages.includes(route.name)) {
        window.location.href = '/'
      } else {
        window.location.reload()
      }
    }

    const enquiryDetailList = computed(() => generalStore.enquiryDetail.enquiryDetailList.enquiry_infos)

    const showModalEnquiryDetail = computed(() => generalStore.enquiryDetail.showModalEnquiryDetail)

    const closeModalEnquiryDetail = () => {
      let data = {
        showModalEnquiryDetail: false,
        enquiryDetailList: {}
      }

      generalStore.setEnquiryDetail(data)
    }

    watchPostEffect(()=>{
      if(showModalEnquiryDetail.value){
        document.getElementById('cover-chat-tf').style.zIndex = '9997'
      }
    })

    const goToAccountSettings = () => {
      return 'account-settings'
    }
    const goToEnquiries = () => {
      return 'enquiries-listing'
    }
    const goToBookings = () => {
      return 'bookings-listing'
    }
    const goToFAQ = () => {
      return 'faq-agent'
    }

    watch(userInfo, async () => {
      await userLogin()
    })

    return {
      loaded,
      showAccountDropdown,
      openAccountDropdown,
      closeAccountDropdown,
      toSignUp,
      toLogin,
      is_verified,
      avatar,
      getUserName,
      handleLogout,
      goToAccountSettings,
      goToEnquiries,
      goToBookings,
      goToFAQ,
      domain,
      forceRerender,
      showModalEnquiryDetail,
      enquiryDetailList,
      userInfo,
      closeModalEnquiryDetail
    }
  }
}
</script>

const pages = [
    "bookings-listing",
    "enquiries-listing",
    "thankyou",
    "thankyou-balance",
    "booking-details",
    "trip-plan-bookable",
    "enquiry-confirmation",
    "account-settings",
    "checkout"
];

export {
    pages
}
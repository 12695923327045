<template>
  <ul class='pt-8'>
    <li v-for='(item, index) in list' :key='index' class='fs-12 lh-14 mt-12'>
      <a :href="item.url" class="black-imp">{{item.title}}</a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'DropDown',
  props: ['list']
}
</script>

<style scoped>
  .black-imp{
    color: black !important
  }
</style>

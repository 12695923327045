<template>
  <div class='mr-30 relative' v-if='token'>
    <img src="https://s3-cdn.designerjourneys.com/static/icons/conversation.png"  />
    <div class='num-msg br-2 pa-2 white fs-10 absolute right-0'>3</div>
  </div>
</template>

<script>
// import { getCookie } from '~/mixins/cookie'
import { useGetCookie } from '~/composables/common/useCustomCookie'

export default {
  name: 'NumberMessages',
  setup() {

    // const token = getCookie('dj_token=')
    const token = useGetCookie('dj_token')

    return { token }
  }
}
</script>

<style scoped>

</style>

<!--<style scoped lang="scss">
@import './MenuMobileStyle.scss';
</style>-->

<template>
<div class="flex items-center">
  <SearchMenu/>
  <client-only>
    <div :key="forceRerender" v-show="is_verified || userInfo.user_id" class="mr-30">
        <tf-chat-notify />
    </div>
  </client-only>
  <div class="right-menu-mobile">
    <button type="button" title="Open menu" aria-label="Open menu" @click='showMegaMB'>
      <img src="https://s3-cdn.designerjourneys.com/static/vectors/general/icon-menu.svg"  width='23' height='18' alt='Menu icon' />
    </button>
  </div>
  <PopupCommon
    :modalTitle="'Enquiry Details'"
    :modalActive="showModalEnquiryDetail"
    @update:close="closeModalEnquiryDetail()"
    maxWidth='600'
    overflowBody
  >
    <template #content>
      <AppTable :datas="enquiryDetailList" type="vertical"></AppTable>
    </template>
  </PopupCommon>
</div>
</template>

<script>
import { computed, ref, watchEffect, watchPostEffect } from 'vue'
import { TfChat, TfChatNotify } from 'tf-chat'
// import { getCookie } from '~/mixins/cookie'
import { useGetCookie } from '~/composables/common/useCustomCookie'
import AppTable from '~/components/ui/table/AppTable'
import PopupCommon from '~/components/ui/popup/PopupCommon'
import SearchMenu from '../SearchMenu/SearchMenu.vue'
import { useAuthStore } from '~/store/pinia/auth'
import { useGeneralInfoStore } from '~/store/pinia/generalInfo'

export default {
  components:{
    SearchMenu,
    PopupCommon,
    AppTable,
    TfChatNotify
  },
  setup() {
    const authStore = useAuthStore()
    const generalStore = useGeneralInfoStore()
    
    const openMegaMenuMB = computed(() => generalStore.generalData.megaMenuInforMB.status)

    const showMegaMB = () => {
      const data = {
        status: !openMegaMenuMB.value,
        statusSubNav: false,
        itemSelected: ''
      }
      generalStore.setMegaMenuStatusMB(data)
      // let tag = document.getElementsByTagName("body")[0]
      // let att = tag.getAttribute("data-mshow")
      // if (openMegaMenuMB.value === "true") tag.setAttribute("data-mshow", "true")
      // else tag.setAttribute("data-mshow", "false")
    }

    // const is_verified = getCookie("is_verified=")
    const is_verified = useGetCookie('is_verified')
    const userInfo = computed(() => authStore.getUserInfo)
    const forceRerender = ref(0)
    watchEffect(async () => {
      const state = computed(() => generalStore.initChat)
      if(state.value.status && userInfo.value.token){
        forceRerender.value += 1
        let sdkToken = state.value.token
        sdkToken && await TfChat.init(sdkToken)
      }
    })

    const enquiryDetailList = computed(() => generalStore.enquiryDetail.enquiryDetailList.enquiry_infos)

    const showModalEnquiryDetail = computed(() => generalStore.enquiryDetail.showModalEnquiryDetail)

    const closeModalEnquiryDetail = () => {
      let data = {
        showModalEnquiryDetail: false,
        enquiryDetailList: {}
      }

      generalStore.setEnquiryDetail(data)
    }

    watchPostEffect(()=>{
      if(showModalEnquiryDetail.value){
        document.getElementById('cover-chat-tf').style.zIndex = '9997'
      }
    })

    return {
      showMegaMB ,
      forceRerender,
      is_verified,
      /*onViewEnquiryDetails,*/
      enquiryDetailList,
      showModalEnquiryDetail,
      /*onChatIsReady,*/
      closeModalEnquiryDetail,
      userInfo
    }
  }
}
</script>

import { TfChatLink } from 'tf-chat'

export default function AutoOpenChat (chat_conv) {
  if (chat_conv) {
    if (chat_conv.indexOf('new-conv') === 0) {
      const segments = chat_conv.split('-');
      const is2Way = segments[2].indexOf('2w') === 0
      const enquiryID = segments[2].substring(2)
      const localDesignerId = segments[3].substring(3)
      if (is2Way) {
        TfChatLink.push({enquiryId: enquiryID, type: 'enquiry-2w'})
      } else {
        TfChatLink.push({enquiryId: enquiryID, type: 'enquiry-3w', lsId: localDesignerId, extraData: {}})
      }

      return
    }

    let open_conv = chat_conv.split('-').join('#');
    TfChatLink.open(open_conv);
  }
}
